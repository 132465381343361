<template>
  <div>
    <h1 class="h3">
      {{ `${$t('product.priceArea')}` }}
    </h1>
    <div class="row mt-4">
      <div class="col-md-5">
        <a-input-search
          v-model="keyword"
          size="large"
          :placeholder="$t('store_list_page.search_products')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" type="danger" icon="search">
            {{ $t('utils.search') }}
          </a-button>
        </a-input-search>
      </div>
      <div class="col-md-3 p-0" />
      <div class="col-md-4 text-right">
        <!-- <a-button
          type="primary"
          size="large"
          :loading="isLoading"
          @click="onShowModal(null)"
        >
          {{ $t('product.addPrice') }}
        </a-button> -->
      </div>
    </div>
    <div class="mt-3">
      <a-table
        class="product-list mt-3 mb-2 bg-white"
        :data-source="areas"
        :row-key="(record) => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        :row-selection="{ selectedRowKeys: selectedProducts, onChange: onSelectChange }"
      >
        <template slot="priceAreaName" slot-scope="text">
          <b style="color: #2196F3">
            {{ text || '-' }}
          </b>
        </template>
        <template slot="coverage" slot-scope="text">
          <div class="d-flex">
            <div>
              {{ onSplit(text)?.init || '-' }}
            </div>
            <div class="ml-3">
              <a-tag
                v-if="onSplit(text)?.count !== 0"
                color="blue"
              >
                {{ '+' + onSplit(text)?.count }}
              </a-tag>
            </div>
          </div>
        </template>
        <template slot="action" slot-scope="record">
          <div class="text-right">
            <a-dropdown>
              <a @click.prevent="">
                <a-icon type="more" />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    key="1"
                    class="py-2"
                    @click="onShowModal(record)"
                  >
                    {{ $t('store_list_page.change_data') }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </a-table>
    </div>
    <div class="mt-3 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="total"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>

    <a-modal
      center
      :width="600"
      :footer="null"
      :closable="false"
      :visible="onShow"
      title="Tambah Harga per Area"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="Nama Harga per Area" class="mb-2" prop="area_name">
          <a-input
            v-model="form.area_name"
            size="large"
            autocomplete="off"
            placeholder="Harga Area"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="Provinsi" class="mb-2">
          <a-select
            v-model="form.province"
            mode="multiple"
            size="large"
            class="select-antd-default"
            option-filter-prop="children"
            placeholder="Cari Provinsi"
            :filter-option="filterOption"
            :options="provinceList"
            :disabled="provinceAll"
            :max-tag-count="5"
            show-search
            allow-clear
            @change="onChangeList('province')"
          />
          <a-checkbox
            name="area"
            @change="onChangeAll('province')"
          >
            Pilih Semua Provinsi
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item label="Kota / Kabupaten" class="mb-2">
          <a-select
            v-model="form.city"
            mode="multiple"
            size="large"
            class="select-antd-default"
            option-filter-prop="children"
            placeholder="Cari Kota"
            :filter-option="filterOption"
            :options="cityList"
            :max-tag-count="5"
            :disabled="cityAll"
            show-search
            allow-clear
            @change="onChangeList('city')"
          />
          <a-checkbox
            name="area"
            @change="onChangeAll('city')"
          >
            Pilih Semua Kota
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item label="Kecamatan" class="mb-2">
          <a-select
            v-model="form.district"
            mode="multiple"
            size="large"
            class="select-antd-default"
            option-filter-prop="children"
            placeholder="Cari Kecamatan"
            :filter-option="filterOption"
            :options="districtList"
            :max-tag-count="5"
            :disabled="districtAll"
            show-search
            allow-clear
          />
          <a-checkbox
            name="area"
            @change="onChangeAll('district')"
          >
            Pilih Semua Kecamatan
          </a-checkbox>
        </a-form-model-item>
        <div class="mt-4 text-center">
          <a-button
            type="primary"
            size="large"
            class="mr-1"
            ghost
            :loading="isLoadingSubmit"
            @click="onShowModal(null)"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :loading="isLoadingSubmit"
            @click="onSubmit"
          >
            Submit
          </a-button>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { priceAreaColumn } from '@/data/columns'
import { getArea, updateStorePriceArea } from '@/api/channels/distributor'
import { getAreaForPriceArea } from '@/api/data'
import { rulesAddPriceArea } from '@/data/rules'

export default {
  name: 'PriceArea',
  data() {
    return {
      keyword: '',
      screenHeight: ((screen.height - 900) * 0.45) + (screen.height * 0.45),
      rules: rulesAddPriceArea,
      sorting: 'Nama',
      activeKey: 'all',
      activeStatus: 'all',
      showProductModal: false,
      showUploadModal: false,
      columns: priceAreaColumn,
      // filteredColumns: [],
      selectedProducts: [],
      sorts: ['Nama'],
      // tabs: ['all', 'unconfirmed', 'confirmed', 'notActive'],
      statusProduct: ['all', 'active', 'notActive'],
      sizeOptions: ['10', '20', '30', '40', '50'],
      detailProduct: {},
      params: {
        page: 1,
        limit: 10,
      },
      loadingSearch: false,
      loadingMasterModal: false,
      controllerList: null,
      controllerOrderCatalog: null,
      eventProcess: false,
      requestId: '',
      isLoadingSync: false,
      isLoading: false,
      isLoadingSubmit: false,
      onShow: false,
      rowData: {},
      areas: [],
      total: 0,
      form: {
        id: '',
        area_name: '',
        province: [],
        city: [],
        district: [],
      },
      cityAll: false,
      districtAll: false,
      provinceAll: false,
      districtList: [],
      cityList: [],
      provinceList: [],
      edit: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    products() {
      return this.$store.state?.productChannel?.products || []
    },
    props() {
      return this.$store.state?.productChannel?.props || {}
    },
    scroll() {
      return { y: this.screenHeight > 400 ? this.screenHeight : 400 }
    },
    tHeight() {
      return !this.products.length
        ? '0px'
        : this.screenHeight > 400
        ? `${this.screenHeight}px`
        : '400px'
    },
    tabs() {
      if (
        this.$route.query &&
        this.$route.query.workspace_id.includes('KINO')
      ) {
        return ['all', 'active', 'notActive']
      }
      return ['all', 'unconfirmed', 'confirmed']
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId || this.$route.query.business_id,
      }
    },
  },
  watch: {
    '$route.query'() {
      this.init()
    },
    onShow: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value === false) {
          this.form = {
            id: '',
            area_name: '',
            province: [],
            city: [],
            district: [],
          }
          this.districtList = []
        }
      },
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    // cancel the request
    if (this.controllerList) {
      this.controllerList.abort()
      this.controllerList = null
    }
    if (this.controllerOrderCatalog) {
      this.controllerOrderCatalog.abort()
      this.controllerOrderCatalog = null
    }
  },
  methods: {
    onChangeList(type) {
      if (type === 'province' && !this.provinceAll) {
        if (this.form.province.length > 0) {
          if (this.edit) {
            this.form.province.forEach(element => {
              this.fetchArea(element, 'city')
            });
          } else {
            const arr = this.form.province.map(item => item)
            const lastIndexValue = arr[arr.length - 1];
            this.fetchArea(lastIndexValue, 'city')
          }
        } else {
          this.fetchArea()
        }
      } else if (type === 'city' && !this.cityAll) {
        if (this.form.city.length > 0) {
          if (this.edit) {
            this.form.city.forEach(element => {
              this.fetchArea(element, 'district')
            });
          } else {
            const arr = this.form.city.map(item => item)
            const lastIndexValue = arr[arr.length - 1];
            this.fetchArea(lastIndexValue, 'district')
          }
        } else {
          this.fetchArea()
        }
      }
    },
    onSubmit() {
      this.isLoadingSubmit = true
      this.$refs.ruleForm.validate(async () => {
        let payload = {
          price_area_name: this.form.area_name,
          coverage: this.form.district.length > 0 ? this.form.district : 
                    this.form.city.length > 0 ? this.form.city : this.form.province,
        }
        if (this.edit) {
          payload = {
            ...payload,
            id: this.form.id,
          }
        }
        if (payload.coverage.length === 0) return this.$message.error('Area Harus Diisi')
        try {
          const res = await updateStorePriceArea({
            method: this.edit ? 'PUT' : 'POST',
            headers: {...this.httpHeader},
            data: payload,
            channel_id: this.$route.params.id,
          })
          if (res.data.message?.toLowerCase() === 'success') {
            this.$message.success('Data Price Area Berhasil Ditambahkan')
            this.edit = false
            this.form = {
              id: '',
              area_name: '',
              province: [],
              city: [],
              district: [],
            }
            this.onShowModal(null)
            this.init()
            this.isLoadingSubmit = false
          }
        } catch (err) {
          if (err) this.$message.error('Oops, inventory Price Area Gagal disimpan', 5)
          this.isLoadingSubmit = false
          throw err
        }
      })
    },
    onShowModal(row) {
      if (row !== null) {
        this.edit = true
        this.form = {
          id: row.id,
          area_name: row.priceAreaName,
          province: row.coverage.map(v => { return this.getCoverageValue(v.id.toString(), 'province') }).filter(e => { return e !== undefined}),
          city: row.coverage.map(v => { return this.getCoverageValue(v.id.toString(), 'city') }).filter(e => { return e !== undefined}),
          district: row.coverage.map(v => { return this.getCoverageValue(v.id.toString(), 'district') }).filter(e => {return e !== undefined}),
        }
      }
      this.onShow = !this.onShow
      this.edit = this.onShow === false ? false : this.edit
    },
    getCoverageValue(area, type) {
      const list = type === 'city' ? this.cityList : type === 'province' ? this.provinceList : this.districtList
      const filter = list.filter(v => {return  v.value === area || v.value === Number(area)})?.[0]?.value
      if (filter !== undefined) {
        return filter
      }
    },
    async onChangeAll(event) {
      if (event === 'district') {
        this.districtAll = !this.districtAll
        this.form.district = this.districtAll ? this.districtList.map(v => { return v.value }) : []
      } else if (event === 'province') {
        this.provinceAll = !this.provinceAll
        this.form.province = this.provinceAll ? this.provinceList.map(v => { return v.value }) : []
        this.fetchArea('228', 'city')
      } else if (event === 'city') {
        this.cityAll = !this.cityAll
        this.form.city = this.cityAll ? this.cityList.map(v => { return v.value }) : []
        for (let i = 0; i < this.form.province.length; i++) {
          await this.fetchArea(this.form.province[i], 'district')
        }
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    onSplit(e) {
      const map = e.map(v => {return v.name})
      const firstFive = map.slice(0, 10);
      const temp = {
        init: firstFive.toString(),
        count: e.length > 10 ? e.length - 10 : 0,
      }
      return temp
    },
    onSearch(val) {
      this.loadingSearch = true
      this.params.page = 1
      this.fetchPriceArea( val.trim() || undefined ) // this.keyword
    },
    init() {
      this.params = {
        page: 1,
        limit: 10,
      }
      this.fetchPriceArea()
      this.fetchArea()
    },
     async fetchArea(area_id = '0', type = 'province') {
      const bodyValue = {
        business_id: this.businessId,
        params: {
          [this.cityAll || this.provinceAll || area_id === '0' ? 'parentId' : 'parent']: area_id,
        },
        url: this.cityAll || this.provinceAll || area_id === '0' ? '/data/areaparent' : '/data/area',
      }
      await getAreaForPriceArea(bodyValue)
        .then(({ data: { list } }) => {
           if (type ===  'city') {
            this.cityList = area_id === '228' ? list : list.concat(this.cityList)
          } else if (type ===  'province') {
            this.provinceList = list
          } else {
            this.districtList = list.concat(this.districtList)
          }
        })
        .catch(err => {
          this.cityList = []
          console.error(err)
        })
    },
    async fetchPriceArea(search = '') {
      this.isLoading = true
      this.areas = []
      const bodyValue = {
        id: this.$route.params.id,
        params: {
          business_id: this.businessId,
          search,
          page: this.params.page,
          limit: this.params.limit,
          sort: 'priceAreaName',
          order: 'createdAt',
        },
      }
      await getArea(bodyValue)
        .then(({ data }) => {
          this.areas = data.data
          this.total = data.total_row
          this.isLoading = false
        })
        .catch(err => {
          console.error(err)
          this.isLoading = false
        })
    },
    onPageChange(pageNumber) {
      this.params.page = pageNumber
      this.fetchPriceArea( this.keyword.trim() || undefined )
    },
    onShowSizeChange(current, pageSize) {
      this.selectedProducts = []
      this.params.page = current
      this.params.limit = pageSize
      this.fetchPriceArea()
    },
    onSelectChange() {},
  },
}
</script>

<style lang="scss">
.product-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.pca-tab-status {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-tab {
    padding: 0 25px !important;
    border-radius: 20px !important;
  }
  .ant-tabs-tab-active {
    background: #ff0a54 !important;
    color: #fff !important;
  }
}
.inbound__button {
  color: #ff0854 !important;
  background: #fff !important;
  border-color: #ff0854 !important;
}
.content {
  width: 100%;
  padding: 1rem;
  // border: 1px solid #999;
  border-radius: 5px;
  border-top-left-radius: 0;

  .search-box {
  width: 228px;
  }
  .list {
  padding: 1rem;
  // border: 1px solid #999;
  border-radius: 5px;
  }
  .header-list {
    font-weight: 900;
    background: #E7ECEF;
    border-radius: 5px ;
    padding: 10px;
  }
  .data-list {
    padding: 5px 0;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px ;
  }

  // ANT DESIGN
  .ant-collapse {
    border: none
  }
}
.pca_modalCustomer .ant-form-item-label {
  line-height: 20px !important;
}
</style>
